import * as Sentry from "@sentry/react";

export const sentryBrowserConfig = () => {
  // eslint-disable-next-line
  import.meta.env.PROD === true && Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: "frontend",
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error']
      }),
      Sentry.replayIntegration()
    ],
    beforeSend: (event, hint) => {
      if (JSON.stringify(event).indexOf('kebab') > -1) {
        console.debug('Ignoring event', event)
        return null
      }
      // if (SENTRY_ENV === 'local') {
      //   console.error(event)
      //   return null
      // }

      // // Check if the event contains the specific error
      // const errorMessage = hint?.originalException?.message || event?.message;
      // const ignoredErrors = [
      //   "Abort fetching component for route",
      //   "attempted to hard navigate",
      //   "Failed to lookup"
      // ]
      // if (ignoredErrors.some((error) => errorMessage?.includes(error))) {
      //   return null
      // }
      return event
    },
    //enabled: import.meta.env.DEV ? false : true,
    autoSessionTracking: globalThis?.window?.document ? true : false, // disable autoSessionTracking in SSR
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.,
    replaysOnErrorSampleRate: 1.0,
  });
};
